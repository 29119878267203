import esb from 'elastic-builder';
import { PremiumTiers } from '../constants';
import { CountryCodes } from '../gazetteer';
import { TElasticRelation } from '../types';

export type SortByOption = 'favourites' | 'popular' | 'new' | 'distance' | 'seoScore';
export type BoundingBoxType = { swlat: number; swlon: number; nelat: number; nelon: number };
export type Location = { lat: number; lon: number };
export type Coordinates = { lat: number; lng: number };

export const isTruthy = <T>(value: T): value is Exclude<T, undefined | null | false> =>
  Boolean(value);

/**
 * Same as `PremiumTiers` with `PremiumTiers.Tier_2_5` omitted.
 */
export enum VenueTier {
  Unverified = PremiumTiers.Unverified,
  Tier_0 = PremiumTiers.Tier_0,
  Tier_1 = PremiumTiers.Tier_1,
  Tier_2 = PremiumTiers.Tier_2,
  Tier_3 = PremiumTiers.Tier_3,
  Tier_4 = PremiumTiers.Tier_4,
}

export interface IUserTargeting {
  targetingBudget?: number;
  targetingYear?: number;
  targetingSeason?: string;
  targetingWeekday?: string;
}

export type QueriesArray = esb.Query[];

export type ScoreFunctionArray = esb.ScoreFunction[];

export interface IElasticResponseVenueFields {
  ac: boolean;
  accommodationRooms: boolean;
  affordable: boolean;
  africanCuisine: boolean;
  alcoholLicense: boolean;
  alternative: boolean;
  asian: boolean;
  asianCatering: boolean;
  asianCuisine: boolean;
  ballroom: boolean;
  bar: boolean;
  barn: boolean;
  barCocktailService: boolean;
  bbDiscountExpiryDate: null | string;
  beach: boolean;
  blankCanvas: boolean;
  bookingsCountLast52Weeks: boolean;
  byobPermitted: boolean;
  bridalChangingFacilities: boolean;
  caribbeanCuisine: boolean;
  castle: boolean;
  casual: boolean;
  childcare: boolean;
  chineseJapaneseCuisine: boolean;
  churchWithinWalkingDistance: boolean;
  cityHotelCityVenue: boolean;
  cityView: boolean;
  classic: boolean;
  confettiPermitted: boolean;
  conferenceCentre: boolean;
  corkageCharge: boolean;
  countryHouseManorHouse: boolean;
  coupleBudgetAvg: boolean;
  coupleBudgetAvgMinDiff: boolean;
  coupleBudgetMax: boolean;
  coupleBudgetMin: boolean;
  cruiseBoatYacht: boolean;
  enquiriesCountLast4Weeks: boolean;
  customOfferExpiryDate: null | string;
  decorations: boolean;
  disabledAccess: boolean;
  easternEuropean: boolean;
  externalCateringPermitted: boolean;
  exclusiveUse: boolean;
  fireworksPermitted: boolean;
  formal: boolean;
  gardenOutdoor: boolean;
  golfCourse: boolean;
  gradeListedBuilding: boolean;
  greatViews: boolean;
  halalCatering: boolean;
  hasBadDebtCoupleside: boolean;
  hasBridebookAwards: boolean;
  hasBridebookOffer: boolean;
  hasIndustryAwards: boolean;
  hasQualityPricing: boolean;
  historic: boolean;
  hogRoast: boolean;
  hotel: boolean;
  indian: boolean;
  inHouseCatering: boolean;
  inHouseDancefloor: boolean;
  inHouseWeddingCoordinator: boolean;
  inHouseWineList: boolean;
  intimate: boolean;
  japanese: boolean;
  kosherCatering: boolean;
  landscapedGardens: boolean;
  latestSpecialOfferDate: null | string;
  latestWeddingFairDate: null | string;
  latinSouthAmericanCuisine: boolean;
  lovelyGrounds: boolean;
  luxury: boolean;
  marqueePermitted: boolean;
  mediterranean: boolean;
  middleEasternCuisine: boolean;
  midweekOfferExpiryDate: null | string;
  modern: boolean;
  museumAttraction: boolean;
  onsiteParking: boolean;
  openFlamePermitted: boolean;
  otherVenueType: boolean;
  outdoor: boolean;
  outdoorCeremonyLicence: boolean;
  outdoorSpace: boolean;
  petFriendly: boolean;
  placeOfWorship: boolean;
  pricePerHeadMax: boolean;
  pricePerHeadMin: boolean;
  pubRestaurant: boolean;
  resort: boolean;
  romantic: boolean;
  rooftop: boolean;
  rustic: boolean;
  shortlistsCountLastWeek: number;
  seasonalOfferExpiryDate: null | string;
  soundSystemAvailable: boolean;
  spaOnsite: boolean;
  sportingVenueStadium: boolean;
  stage: boolean;
  statelyHome: boolean;
  stylish: boolean;
  tier: number;
  tierUpgradedInLast26Weeks: boolean;
  totalInclusivePriceMax: boolean;
  totalInclusivePriceMin: boolean;
  townHallRegistryOffice: boolean;
  transportation: boolean;
  trustedPartnerSince: number;
  unique: boolean;
  uniqueVenueType: boolean;
  unusual: boolean;
  urban: boolean;
  vegan: boolean;
  venueHirePriceMax: boolean;
  venueHirePriceMin: boolean;
  videoToursCount: number;
  warehouseFactory: boolean;
  waterView: boolean;
  weddingLicence: boolean;
  westernEuropeanCuisine: boolean;
  wifi: boolean;
  winery: boolean;
  relations: TElasticRelation[];
}

export interface IElasticSupplier extends Partial<IElasticResponseVenueFields> {
  bestOfBritain: boolean;
  country: string;
  countryCode: CountryCodes;
  county: string;
  currency: string;
  description: string;
  deleted: boolean;
  email: boolean;
  id: string;
  lat: number;
  lng: number;
  name: string;
  photos: string;
  profileScore: number;
  publicId: string;
  reviewsCount: number;
  reviewsOnlyCount: number;
  testimonialsOnlyCount: number;
  reviewsStars: number;
  statusMessage: string;
  thumbnail: string;
  town: string;
  type: string;
  connections?: {
    recommendedBy: string[];
    worksWith: string[];
    recommends: string[];
    relatedBy: string[];
    relatesWith: string[];
  };
}

export interface ISupplierSearchRank {
  id: string;
  county?: string;
  profileScore?: number;
  responseScore?: number;
  name?: string;
  position?: number;
  searchPositionPredictions?: {
    tier0?: number;
    tier1?: number;
    tier2?: number;
    tier3?: number;
  };
}

export enum SearchType {
  Town = 'town',
  Region = 'region',
  County = 'county',
  Country = 'country',
  CustomArea = 'customArea',
  Map = 'map',
}
